<template>
  <v-app>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-12 order-xl-1">
        <KTPortlet
          v-bind:title="!troubleshoot? 'Troubleshoot':'Running Diagnostics for '+domain_name"
        >
          <template v-slot:toolbar></template>
          <template v-slot:body v-if="troubleshoot">
            <v-stepper v-model="e6" vertical>
              <div class="row">
                <div class="col-sm-4 col-md-4 col-lg-4">
                  <v-stepper-step :complete="e6 > 1" step="1">Home Page</v-stepper-step>
                  <v-stepper-step :complete="e6 > 2" step="2">Blog Page</v-stepper-step>
                  <v-stepper-step :complete="e6 > 3" step="3">Domain & Subscription</v-stepper-step>
                  <v-stepper-step :complete="e6 > 4" step="4">Logo</v-stepper-step>
                  <v-stepper-step :complete="e6 > 5" step="5">Website Title</v-stepper-step>
                  <v-stepper-step :complete="e6 > 6" step="6">Header menu</v-stepper-step>
                  <v-stepper-step :complete="e6 > 7" step="7">Footer menu</v-stepper-step>
                  <v-stepper-step :complete="e6 > 8" step="8">Store</v-stepper-step>
                  <v-stepper-step :complete="e6 > 9" step="9">SSL</v-stepper-step>
                  <v-stepper-step :complete="e6 > 10" step="10">Basic Layouts</v-stepper-step>
                  <v-stepper-step :complete="e6 > 11" step="11">Test Result</v-stepper-step>
                </div>
                <div class="col-sm-8 col-md-8 col-lg-8">
                  <div class="text-center">
                    <v-stepper-content step="1">
                      <v-progress-circular
                        v-if="!has_error"
                        :width="6"
                        :size="70"
                        color="green"
                        indeterminate
                      ></v-progress-circular>
                      <div v-else>
                        <v-row>
                          <v-col class="d-flex" cols="10" sm="10">
                            <v-select
                              :items="websitePages"
                              v-model="website_setting.home_page_id"
                              label="Select Home Page"
                              item-text="title"
                              item-value="id"
                              persistent-hint
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col class="d-flex" cols="2" sm="2">
                            <v-btn class="btn btn-primary" @click="updateWebsiteSetting">save</v-btn>
                          </v-col>
                        </v-row>
                        <div v-if="error != undefined && error != null && error != ''">
                          <b-alert show :variant="error.flag=='danger'?'danger':'warning'">
                            {{error.flag=="danger"?'ERROR :':'WARNING :'}}
                            <strong>{{error.msg}}</strong>
                          </b-alert>
                        </div>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                      <v-progress-circular
                        v-if="!has_error"
                        :width="6"
                        :size="70"
                        color="green"
                        indeterminate
                      ></v-progress-circular>
                      <div v-else>
                        <v-row>
                          <v-col class="d-flex" cols="8" sm="8">
                            <v-select
                              :items="websitePages"
                              v-model="website_setting.blog_page_id"
                              label="Select Blog Page"
                              item-text="title"
                              item-value="id"
                              persistent-hint
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col class="d-flex" cols="2" sm="2">
                            <v-btn class="btn btn-primary" @click="updateWebsiteSetting">save</v-btn>
                          </v-col>
                          <v-col class="d-flex" cols="2" sm="2">
                            <v-btn class="btn btn-primary" @click="skip">skip</v-btn>
                          </v-col>
                        </v-row>
                        <div v-if="error != undefined && error != null && error != ''">
                          <b-alert show :variant="error.flag=='danger'?'danger':'warning'">
                            {{error.flag=="danger"?'ERROR :':'WARNING :'}}
                            <strong>{{error.msg}}</strong>
                          </b-alert>
                        </div>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                      <v-progress-circular
                        v-if="!has_error"
                        :width="6"
                        :size="70"
                        color="green"
                        indeterminate
                      ></v-progress-circular>
                      <div v-else>
                        <b-alert show :variant="error.flag=='danger'?'danger':'warning'">
                          {{error.flag=="danger"?'ERROR :':'WARNING :'}}
                          <strong>{{error.msg}}</strong>
                        </b-alert>

                        <v-col class="d-flex" cols="12" sm="12">
                          <router-link
                            class="btn btn-primary text-white ml-2"
                            :to="{'name':'website-subscription-plan', params:{'domainname':domain_name}}"
                          >Subscription Plans</router-link>

                          <v-btn class="btn btn-primary ml-2" @click="skip">skip</v-btn>
                        </v-col>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="4">
                      <v-progress-circular
                        v-if="!has_error"
                        :width="6"
                        :size="70"
                        color="green"
                        indeterminate
                      ></v-progress-circular>
                      <div v-else>
                        <v-row>
                          <v-col class="d-flex" cols="8" sm="8">
                            <v-file-input
                              v-model="file"
                              color="deep-purple accent-4"
                              label="File input"
                              placeholder="Select your logo"
                              prepend-inner-icon="mdi-paperclip"
                              outlined
                              :show-size="1000"
                            ></v-file-input>
                          </v-col>
                          <v-col class="d-flex" cols="2" sm="2">
                            <v-btn class="btn btn-primary" @click="saveLogo">save</v-btn>
                          </v-col>
                          <v-col class="d-flex" cols="2" sm="2">
                            <v-btn class="btn btn-primary" @click="skip">skip</v-btn>
                          </v-col>
                        </v-row>
                        <div v-if="error != undefined && error != null && error != ''">
                          <b-alert show :variant="error.flag=='danger'?'danger':'warning'">
                            {{error.flag=="danger"?'ERROR :':'WARNING :'}}
                            <strong>{{error.msg}}</strong>
                          </b-alert>
                        </div>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="5">
                      <v-progress-circular
                        v-if="!has_error"
                        :width="6"
                        :size="70"
                        color="green"
                        indeterminate
                      ></v-progress-circular>
                      <div v-else>
                        <v-row>
                          <v-col class="d-flex" cols="8" sm="8">
                            <v-text-field
                              v-model="website_setting.site_title"
                              color="deep-purple accent-4"
                              label="File input"
                              placeholder="Site Title"
                              outlined
                              :show-size="1000"
                            ></v-text-field>
                          </v-col>
                          <v-col class="d-flex" cols="2" sm="2">
                            <v-btn class="btn btn-primary" @click="updateWebsiteSetting">save</v-btn>
                          </v-col>
                          <v-col class="d-flex" cols="2" sm="2">
                            <v-btn class="btn btn-primary" @click="skip">skip</v-btn>
                          </v-col>
                        </v-row>
                        <div v-if="error != undefined && error != null && error != ''">
                          <b-alert show :variant="error.flag=='danger'?'danger':'warning'">
                            {{error.flag=="danger"?'ERROR :':'WARNING :'}}
                            <strong>{{error.msg}}</strong>
                          </b-alert>
                        </div>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="6">
                      <v-progress-circular
                        v-if="!has_error"
                        :width="6"
                        :size="70"
                        color="green"
                        indeterminate
                      ></v-progress-circular>
                      <div v-else>
                        <b-alert show :variant="error.flag=='danger'?'danger':'warning'">
                          {{error.flag=="danger"?'ERROR :':'WARNING :'}}
                          <strong>{{error.msg}}</strong>
                        </b-alert>

                        <v-col class="d-flex" cols="12" sm="12">
                          <router-link
                            class="btn btn-primary text-white ml-2"
                            :to="{'name':'manage-menu', params:{'domainname':domain_name}}"
                          >Manage Website</router-link>

                          <!-- <v-btn class="btn btn-primary ml-2" @click="skip">skip</v-btn> -->
                        </v-col>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="7">
                      <v-progress-circular
                        v-if="!has_error"
                        :width="6"
                        :size="70"
                        color="green"
                        indeterminate
                      ></v-progress-circular>
                      <div v-else>
                        <b-alert show :variant="error.flag=='danger'?'danger':'warning'">
                          {{error.flag=="danger"?'ERROR :':'WARNING :'}}
                          <strong>{{error.msg}}</strong>
                        </b-alert>

                        <v-col class="d-flex" cols="12" sm="12">
                          <router-link
                            class="btn btn-primary text-white ml-2"
                            :to="{'name':'manage-menu', params:{'domainname':domain_name}}"
                          >Manage Menu</router-link>

                          <v-btn class="btn btn-primary ml-2" @click="skip">skip</v-btn>
                        </v-col>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="8">
                      <v-progress-circular
                        v-if="!has_error"
                        :width="6"
                        :size="70"
                        color="green"
                        indeterminate
                      ></v-progress-circular>
                      <div v-else>
                        <v-row>
                          <v-col class="d-flex" cols="8" sm="8">
                            <v-switch
                              v-model="website_setting.sell_product_online"
                              label="Enable Ecommerce"
                            ></v-switch>
                          </v-col>
                          <v-col class="d-flex" cols="2" sm="2">
                            <v-btn class="btn btn-primary" @click="updateWebsiteSetting">save</v-btn>
                          </v-col>
                          <v-col class="d-flex" cols="2" sm="2">
                            <v-btn class="btn btn-primary" @click="skip">skip</v-btn>
                          </v-col>
                        </v-row>
                        <div v-if="error != undefined && error != null && error != ''">
                          <b-alert show :variant="error.flag=='danger'?'danger':'warning'">
                            {{error.flag=="danger"?'ERROR :':'WARNING :'}}
                            <strong>{{error.msg}}</strong>
                          </b-alert>
                        </div>
                      </div>
                    </v-stepper-content>

                    <v-stepper-content step="9">
                      <v-progress-circular
                        v-if="!has_error"
                        :width="6"
                        :size="70"
                        color="green"
                        indeterminate
                      ></v-progress-circular>
                      <div v-else>
                        <v-row>
                          <v-col class="d-flex" cols="12" sm="12">
                            <router-link
                              class="btn btn-primary text-white ml-2"
                              target="_blank"
                              :to="{'name':'website-ssl-certificate', params:{'domainname':domain_name}}"
                            >Manage SSL</router-link>

                            <v-btn class="btn btn-primary ml-2" @click="skip">skip</v-btn>
                          </v-col>
                        </v-row>
                        <div v-if="error != undefined && error != null && error != ''">
                          <b-alert show :variant="error.flag=='danger'?'danger':'warning'">
                            {{error.flag=="danger"?'ERROR :':'WARNING :'}}
                            <strong>{{error.msg}}</strong>
                          </b-alert>
                        </div>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="10">
                      <v-progress-circular
                        v-if="!has_error"
                        :width="6"
                        :size="70"
                        color="green"
                        indeterminate
                      ></v-progress-circular>
                      <div v-else>
                        <v-row>
                          <v-col class="d-flex" cols="12" sm="12">
                            <router-link
                              class="btn btn-primary text-white ml-2"
                              target="_blank"
                              :to="{'name':'website-layouts', params:{'domainname':domain_name}}"
                            >Manage Layout</router-link>
                            <v-btn
                              class="btn btn-primary ml-2"
                              @click="contiueTroubleshoot('10', 'check_layout')"
                            >continue</v-btn>
                            <v-btn class="btn btn-primary ml-2" @click="skip">skip</v-btn>
                          </v-col>
                        </v-row>
                        <div v-if="error != undefined && error != null && error != ''">
                          <b-alert show :variant="error.flag=='danger'?'danger':'warning'">
                            {{error.flag=="danger"?'ERROR :':'WARNING :'}}
                            <strong>{{error.msg}}</strong>
                          </b-alert>
                        </div>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="11">
                      <h3>Diagnostics Completed</h3>
                      <br />
                      <v-btn
                        class="btn btn-info"
                        color="primary"
                        @click="startTroubleShoot"
                      >Troubleshoot Now</v-btn>
                      <router-link
                        class="btn btn-primary text-white ml-2"
                        :to="{'name':'manage-websites', params:{'domainname':domain_name}}"
                      >Manage Website</router-link>
                    </v-stepper-content>
                  </div>
                </div>
              </div>
            </v-stepper>
          </template>
          <template v-slot:body v-else>
            <div class="text-left">
              <p>
                <i class="fa fa-ambulance kt-font-info kt-font-big"></i> Welcome to our automated intelligence tool which will detect error on your website settings. If you are having issue with your
                website, you can run a quick diagnosis to check any system error. If this does not solves your problem, please contact our support
                via facebook messenger or email us your query to contact@yelko.com
              </p>
              <h5>Our system will check for errors on following area:</h5>
              <ul class="pl-10">
                <li>Page (Home & Blog)</li>
                <li>Domain </li>
                <li>Logo & Title</li>
                <li>Menu (Header & Footer)</li>
                <li>SSL Certificate</li>
                <li>Section Layout</li>
              </ul>
              <br />
              <v-btn
              text
              class="mr-3"
              :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
              outlined
              >
              <i class="fas fa-arrow-left"></i> Back
              </v-btn>
              <v-btn
                class="btn btn-info"
                color="primary"
                @click="startTroubleShoot"
              >Click here to start diagnosis <i class="fas fa-arrow-right"></i></v-btn>

            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </v-app>
</template>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import WebsiteService from "@/services/Websites/WebsiteService";
import WebsitePage from "@/services/Websites/WebsitePageService";
import WebsiteSetting from "@/services/Websites/WebsiteSettingService";

const websiteService = new WebsiteService();
const websitePageService = new WebsitePage();
const WebsiteSettingService = new WebsiteSetting();

export default {
  components: { KTPortlet },
  data() {
    return {
      timer: 3000,
      domain_name: null,
      e6: 1,
      index: 0,
      steps: [
        "check-home",
        "check-blog",
        "check-domain",
        "check-logo",
        "check-title",
        "check-header",
        "check-footer",
        "check-store",
        "check_ssl",
        "check_layout"
      ],
      step: null,
      error: {},
      troubleshoot: false,
      has_error: false,
      websitePages: [],
      website_setting: {},
      setting: {},
      file: null
    };
  },
  mounted() {
    this.domain_name = this.$route.params.domainname;
    this.getWebsitePages();
    this.getSettings();
  },
  methods: {
    startTroubleShoot() {
      this.e6 = 1;
      this.error = [];
      this.index = 0;
      this.troubleshoot = true;
      this.step = "check-home";
      setTimeout(() => {
        if (this.step) this.toubleshootNow();
      }, this.timer);
    },

    toubleshootNow() {
      websiteService
        .troubleshoot(this.domain_name, this.step)
        .then(response => {
          if (!response.data.status) {
            this.error = response.data;
            this.has_error = true;
          } else {
            this.index += 1;
            this.step = this.steps[this.index];
            this.error[this.index] = null;
            setTimeout(() => {
              if (this.step) this.toubleshootNow();
            }, this.timer);
            this.e6 += 1;
          }
        })
        .catch(error => {});
    },
    skip() {
      this.has_error = false;
      this.error = {};
      this.index += 1;
      this.step = this.steps[this.index];
      this.error[this.index] = null;
      if(this.index <=this.steps.length){
      setTimeout(() => {
        if (this.step) this.toubleshootNow();
      }, this.timer);
      }
      this.e6 += 1;
    },
    contiueTroubleshoot(index, step) {
      this.has_error = false;
      this.error = {};
      this.step = step;
      this.error[index] = null;
      setTimeout(() => {
        if (this.step) this.toubleshootNow();
      }, this.timer);
      this.e6 = index;
    },
    getWebsitePages() {
      websitePageService.paginate(this.domain_name, "default").then(res => {
        this.websitePages = res.data.pages;
      });
    },
    getSettings() {
      WebsiteSettingService.paginate(this.domain_name).then(res => {
        this.setting = res.data;
      });
    },
    updateWebsiteSetting() {
      WebsiteSettingService.update(
        this.domain_name,
        this.setting.id,
        this.website_setting
      )
        .then(res => {
          this.has_error = false;
          this.error = {};
          this.toubleshootNow();
          this.$snotify.success("Setting Updated Successfully");
        })
        .catch(err => {});
    },
    saveLogo() {
      let fd = new FormData();
      fd.append("site_logo", this.file);
      WebsiteSettingService.update(this.domain_name, this.setting.id, fd)
        .then(res => {
          this.has_error = false;
          this.error = {};
          this.startTroubleShoot();
          this.$snotify.success("Setting Updated Successfully");
        })
        .catch(err => {});
    }
  }
};
</script>
<style>
.v-stepper__step {
  margin: 10px !important;
  font-size: 15px !important;
}
.v-stepper__step--active {
  background: #efefef !important;
  /* padding: 15px !important; */
  border-radius: 10px !important;
  color: black !important;
  font-size: 15px !important;
  font-weight: bold !important;
  position: relative;
  z-index: 1;
}
.stpper-optional-text {
  font-size: 14px !important;
  margin-top: 5px;
}
.v-stepper__step.v-stepper__step--active:after {
  content: "";
  position: absolute;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 30px solid #efefef;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
}
.v-stepper__step .v-stepper__step--inactive {
  font-size: 15px !important;
  font-weight: bold !important;
}
.v-stepper__step--active .v-stepper__label {
  /* text-shadow: 0px 0px 0px black; */
  color: grey !important;
  font-size: 15px !important;
  font-weight: bold !important;
}

.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin-left: 0;
}
</style>
